@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html {
    /* Now 10px = 1rem! */
    font-size: 62.5%;
}

body {
    background-color: #ffffff;
    margin: 0;
    font-family: 'Open Sans', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Open Sans', sans-serif !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0040e4;
}

.invoice-customer-option-disabled-true,
.invoice-product-option-disabled-true {
    display: none;
}
