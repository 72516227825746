.declaration-table-row > td:first-child {
    border-top-left-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
}
.declaration-table-row > td:last-child {
    border-top-right-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
}

.declaration-table-row-red > td {
    background: #fdefef;
    border-top: 1.5px solid #ff6961 !important;
    border-bottom: 1.5px solid #ff6961 !important;
}

.declaration-table-row-red > td:first-child {
    border-left: 1.5px solid #ff6961 !important;
}
.declaration-table-row-red > td:last-child {
    border-right: 1.5px solid #ff6961 !important;
}

.declaration-table-row-orange > td {
    background: #fff6e6;
    border-top: 1.5px solid #ffb11f !important;
    border-bottom: 1.5px solid #ffb11f !important;
}

.declaration-table-row-orange > td:first-child {
    border-left: 1.5px solid #ffb11f !important;
}
.declaration-table-row-orange > td:last-child {
    border-right: 1.5px solid #ffb11f !important;
}

.declaration-table-row-yellow > td {
    background: #fffbe6;
    border-top: 1.5px solid #ffd000 !important;
    border-bottom: 1.5px solid #ffd000 !important;
}

.declaration-table-row-yellow > td:first-child {
    border-left: 1.5px solid #ffd000 !important;
}
.declaration-table-row-yellow > td:last-child {
    border-right: 1.5px solid #ffd000 !important;
}
