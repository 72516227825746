.access-denied-message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    background-color: #f8d7da;
    border: 1px solid #dc3545;
}

.error-icon {
    font-size: 48px;
    color: #dc3545;
    margin-right: 20px;
}

.error-text {
    text-align: left;
}

.error-text h2 {
    font-size: 24px;
    margin: 0;
}

.error-text p {
    font-size: 16px;
    margin: 10px 0;
}
