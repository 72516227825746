@import '~antd/dist/antd.less';

&::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #f1f1f1;
}

&::-webkit-scrollbar-thumb {
    background: #383752;
}

&::-webkit-scrollbar-thumb:hover {
    background: #383752;
}

@keyframes flashBackground {
    0% {
        background-color: rgb(216, 216, 216);
    }
    33% {
        background-color: rgb(216, 216, 216);
    }
    100% {
        background-color: default;
    }
}

.flashBackground {
    overflow: hidden;
    -webkit-animation-name: flashBackground;
    -webkit-animation-duration: 900ms;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-name: flashBackground;
    animation-duration: 900ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

@primary-color: #1F92FB;