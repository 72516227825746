.pictureContainer :global(.ant-upload-list-picture-card-container) {
    width: 180px !important;
    height: 180px !important;
    display: grid;
    place-items: center;
}
.pictureContainer :global(.ant-upload-select) {
    width: 180px !important;
    height: 180px !important;
}

.hideUpload :global(.ant-upload-select) {
    display: none !important;
}
